<template>
  <cw-page
    icon="timer_off"
    class="cw-business-loan-expired"
  >
    <template #hero-title>
      <translate>
        Fill out a new loan application
      </translate>
    </template>
    <template #hero-text>
      <translate>
        Unfortunately, the loan agreement has expired.
      </translate>
    </template>
    <v-row>
      <v-col
        class="
          cw-border-container
          cw-border-container--primary
        "
        cols="12"
        md="8"
      >
        <p>
          <translate>
            The maturity date of the loan agreement was %{ expirationDate }.
          </translate>
        </p>
        <p class="ma-0">
          <translate>Please fill out the loan application again.</translate>
        </p>
      </v-col>
    </v-row>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        dark
        color="purple-one darken-2"
        href="https://www.saldo.com"
        @click="$eventLogger.clickEvent($event)"
      >
        <translate>Apply for a loan</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwBusinessLoanExpired',

  computed: {
    ...mapGetters({
      expirationDate: 'application/expirationDate',
    }),
  },
};
</script>
